@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  background: #24C6DC;
  background: -webkit-linear-gradient(to right, #514A9D, #24C6DC);
  background: linear-gradient(to right, #514A9D, #24C6DC);
}
html {
  scroll-behavior: smooth;
  /* Must assign a font size here in order to use rem */
  font-size: 14px;
}
h1 {
  font-weight: 900;
  /* This calc function allows us to combine our rem with a diff value (vw)
  This way, it is always taking into account the viewport width to make it responsive */
  font-size: calc(1rem + 3vw);
}
h2 {
  font-weight: 700;
  font-size: calc(1rem + 3vw);
}
p {
  font-weight: 500;
  font-size: calc(0.75rem + 1vw);
}
input, textarea, button {
  font-weight: 500;
  font-size: calc(0.75rem + 1vw);
}
a {
  font-weight: 900;
  font-size: calc(0.6rem + 1vw);
  color: white;
}
li {
  font-size: calc(0.6rem + 1vw);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
