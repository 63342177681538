@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  background: #24C6DC;
  background: linear-gradient(to right, #514A9D, #24C6DC);
}
html {
  scroll-behavior: smooth;
  /* Must assign a font size here in order to use rem */
  font-size: 14px;
}
h1 {
  font-weight: 900;
  /* This calc function allows us to combine our rem with a diff value (vw)
  This way, it is always taking into account the viewport width to make it responsive */
  font-size: calc(1rem + 3vw);
}
h2 {
  font-weight: 700;
  font-size: calc(1rem + 3vw);
}
p {
  font-weight: 500;
  font-size: calc(0.75rem + 1vw);
}
input, textarea, button {
  font-weight: 500;
  font-size: calc(0.75rem + 1vw);
}
a {
  font-weight: 900;
  font-size: calc(0.6rem + 1vw);
  color: white;
}
li {
  font-size: calc(0.6rem + 1vw);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.About_AboutMe__1mXOH {
  padding-bottom: 5%;
  color: white;
}

.About_AboutMe__1mXOH .About_Container__1tPbZ {
  display: -webkit-flex;
  display: flex;
}

.About_AboutMe__1mXOH .About_Container__1tPbZ .About_Text__3JV-V {
  width: 47%;
  margin-left: 3%;
}

.About_AboutMe__1mXOH .About_Container__1tPbZ .About_Text__3JV-V h2 {
  font-weight: 900;
}

.About_AboutMe__1mXOH .About_Container__1tPbZ .About_Photo__KaLwD {
  width: 50%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  /* This align items made our photo not super stretched out because of the width: 90% below */
  -webkit-align-items: center;
          align-items: center;
}

.About_AboutMe__1mXOH .About_Container__1tPbZ .About_Photo__KaLwD .About_Me__2E3_Y {
  width: 90%;
  max-width: 500px;
  border-radius: 10%;
}

.About_AboutMe__1mXOH a:hover {
  color: #24c6dc;
}

@media (max-width: 500px) {
  .About_AboutMe__1mXOH .About_Container__1tPbZ {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .About_AboutMe__1mXOH .About_Container__1tPbZ .About_Text__3JV-V {
    width: 80%;
  }
  .About_AboutMe__1mXOH .About_Container__1tPbZ .About_Photo__KaLwD {
    margin-top: 20px;
    width: 70%;
  }
}
.PageHeader_Container__33Pan {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding-bottom: 2%;

}
.PageHeader_Container__33Pan hr {
  position: absolute;
  left: 0;
  width: 12vw;
  border: 1px solid #24c6dc;
}
.PageHeader_Container__33Pan h1 {
  color: white;
}
@media (max-width: 350px) {
  .PageHeader_Container__33Pan hr {
    display: none;
  }
}
.Home_Home__Hrybx {
  display: -webkit-flex;
  display: flex;
  /* Repsonsive height */
  height: 85vh;
  padding-top: 80px;
  position: relative;
}

.Home_Home__Hrybx .Home_Aztecs__2Uj7l {
  width: 20%;
  max-width: 150px;
  position: absolute;
  bottom: 0;
  left: 2%;
}

.Home_Home__Hrybx .Home_Container__v5Syz {
  position: absolute;
  top: 15%;
  left: 2%;
  color: white;
  /* color: black; */
}

.Home_Home__Hrybx .Home_Container__v5Syz .Home_Hello__2hWvY {
  /* Keeping this font size because we want it to be bigger than the normal h1 */
  font-size: calc(1.7rem + 3vw);
}

.Home_Home__Hrybx .Home_Container__v5Syz h1 {
  margin: 0;
}

.Home_Home__Hrybx .Home_Right__2vFBa {
  color: white;
  width: 60%;
  max-width: 500px;
  position: absolute;
  right: 10%;
  top: 20%;
}
@media (max-width: 500px) {
  .Home_Home__Hrybx {
    height: 72vh;
  }
}
.NavBar_NavBar__-XYZQ {
  background: rgb(64, 64, 64, .9);
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 999;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.NavBar_NavBar__-XYZQ nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.NavBar_NavBar__-XYZQ nav ul li {
  display: inline-block;
  /* Responsive margin */
  margin: 1.5vw;
}
.NavBar_NavBar__-XYZQ nav ul li a {
  text-decoration: none;
  color: white;
}
.NavBar_NavBar__-XYZQ a:hover {
  border-bottom: 5px solid #24c6dc;
}
.NavBar_NavBar__-XYZQ .NavBar_MobileNavigation__28OkV {
  display: none;
}


@media (max-width: 990px) {
  .NavBar_NavBar__-XYZQ .NavBar_Nav__1Sk1o {
    display: none;
  }
  .NavBar_NavBar__-XYZQ .NavBar_MobileNavigation__28OkV {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
  .NavBar_NavBar__-XYZQ .NavBar_MobileNavigation__28OkV .NavBar_Hamburger__2E5HQ {
    position: absolute;
    right: 5%;
    cursor: pointer;
  }
  .NavBar_NavBar__-XYZQ nav ul {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 80px;
    right: 0;
    width: 100%;
    background: rgb(64, 64, 64, .9);
    border-top: solid 2px #24c6dc;
    
  }
  .NavBar_NavBar__-XYZQ nav ul li {
    padding-left: 15%;
  }
  .NavBar_NavBar__-XYZQ {
    opacity: 1;
  }
  .NavBar_NavBar__-XYZQ a:hover {
    border-bottom: none;
    color: #24c6dc
  }

}

/* Use these stylings for the picture in our welcome component instead of this one */
.Projects_Projects__1ECks {
  position: relative;
  /* background-color: blue; */
}
.Projects_Projects__1ECks .Projects_ProjectsContent__2zWlJ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.Projects_Projects__1ECks .Projects_Cards__2kQQL{
  width: 80%;
  z-index: 1;
}
.Projects_Projects__1ECks .Projects_Paragraph__2Cs2S {
  width: 65%;
  z-index: 1;
  color: white;

}
.Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Thumbnail__3sMJs img {
  max-width: 489px;
  /* Responsive Design */
  width: 100%;
  opacity: .8;
  transition: all .2s ease-in-out; 

} 
.Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Thumbnail__3sMJs img:hover {
  opacity: 1;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  transition: 1.5s;
}

.Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Title__3m1xh {
  font-size: calc(1.5rem + 1vw);
  /* color: #24c6dc; */
  font-weight: 1000;
}
.Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Links__2-r-_ {
  padding-top: 10%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  width: 100%;
}

.Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Links__2-r-_ a:hover{  
  color:  #24c6dc;
}
.Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Text__HVSPJ {
  padding-left: 2.5%;
  padding-right: 2.5%;
  /* Responsive Design */
  width: 45%;
}

.Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Thumbnail__3sMJs {
  position: relative;
  /* Responsive Design */
  padding: 2.5%;
  width: 45%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.Projects_Projects__1ECks .Projects_Cards__2kQQL .Projects_ProjectContainer__3ajfo {
  display: -webkit-flex;
  display: flex;
  background: rgb(64, 64, 64, .7);
  color: white;
  margin-bottom: 5%;
  /* Responsive Design (height) */
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px white;
  padding-top: 10px;
  padding-bottom: 10px;
}
@media (max-width: 850px) {
  .Projects_Projects__1ECks .Projects_Cards__2kQQL .Projects_ProjectContainer__3ajfo {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Text__HVSPJ {
    width: 80%;
  }
  .Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Thumbnail__3sMJs {
    width: 80%;
  }
}
@media (max-width: 500px) {
  .Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Text__HVSPJ {
    width: 90%;
  }
  .Projects_Projects__1ECks .Projects_ProjectContainer__3ajfo .Projects_Thumbnail__3sMJs {
    width: 90%;
  }
}

.Contact_Contact__2CqGJ {
  position: relative;
  margin-bottom: 50px;
}
.Contact_Contact__2CqGJ .Contact_Content__2y5Zy {
  display: -webkit-flex;
  display: flex;
  /* margin-right: 20px; */
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.Contact_Contact__2CqGJ .Contact_Form__Doakq {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 45%;
  /* left: 5%; */
  /* margin-left: 50px; */
}

.Contact_Contact__2CqGJ .Contact_Form__Doakq input, textarea {
  margin-bottom: 20px;
  font-family: 'Lato', sans-serif;
  padding: 10px;
  border: solid 2px black;
  border-radius: 1%;
}
.Contact_Contact__2CqGJ .Contact_Form__Doakq Button {
  width: 25%;
  height: 40px;
}
.Contact_Contact__2CqGJ .Contact_Form__Doakq Button:hover {
  cursor: pointer;
}
.Contact_Contact__2CqGJ ul {
  list-style-type: none;
  margin-left: 50px;
  background: rgb(64, 64, 64, 0.3);
  color: white;
  margin-bottom: 5%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px white;
  margin-top: 0px;
  padding: 20px;
}
.Contact_Contact__2CqGJ ul span {
  color: #24c6dc;
  font-weight: 1000;
}
.Contact_Contact__2CqGJ ul li {
  padding-bottom: 15px;
  text-decoration: none;
}
.Contact_Contact__2CqGJ a {
  text-decoration: none;
}

@media (max-width: 500px) {
  .Contact_Contact__2CqGJ .Contact_Form__Doakq {
    width: 80%;
    margin: 0 auto;
  }
  .Contact_Contact__2CqGJ .Contact_Content__2y5Zy {
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
  .Contact_Contact__2CqGJ .Contact_Content__2y5Zy br {
    display: none;
  }
  .Contact_Contact__2CqGJ ul {
    margin: 20px;
  }
  .Contact_Contact__2CqGJ Button {
    margin: 0 auto;
  }
}

.Footer_Footer__238hM {
  background: #404040;
  opacity: 0.9;
  height: 10vh;  
  display: -webkit-flex;  
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Footer_Footer__238hM .Footer_Icons__3PTSJ {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;

}
.Footer_Footer__238hM .Footer_Icons__3PTSJ svg path {
  cursor: pointer;
}
.Skills_Skills__2xCDS {
  /* background-color: ; */
  padding-bottom: 5%;
}
.Skills_Skills__2xCDS p {
  padding-left: 25%;
  width: 50%;
  color: white;
}
.Skills_Skills__2xCDS .Skills_List__2S3nb ul {
  list-style: none;
  padding: 0;

}
.Skills_Skills__2xCDS .Skills_Container__3MRAx {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: 10%;
  margin-right: 10%;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  color: white;
}
.Skills_Skills__2xCDS .Skills_Container__3MRAx .Skills_List__2S3nb {
  /* Add a flex basis of 50% to our div of projecct cards and add flex wrap as well. */
  -webkit-flex-basis: 49%;
          flex-basis: 49%;
  background: rgb(64, 64, 64, .7);
  box-shadow: 0 0 1vw 0.3vw white;
  border-radius: 9px;
  margin-bottom: 10px;
}
.Skills_Skills__2xCDS .Skills_List__2S3nb ul li {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

@media (max-width: 714px) {
  .Skills_Skills__2xCDS p {
    width: 80%;
    padding-left: 10%;
  }
  .Skills_Skills__2xCDS .Skills_Container__3MRAx {
    margin-left: 2%;
    margin-right: 2%;
  }
}
