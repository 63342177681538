.Container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-bottom: 2%;

}
.Container hr {
  position: absolute;
  left: 0;
  width: 12vw;
  border: 1px solid #24c6dc;
}
.Container h1 {
  color: white;
}
@media (max-width: 350px) {
  .Container hr {
    display: none;
  }
}