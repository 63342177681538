.Home {
  display: flex;
  /* Repsonsive height */
  height: 85vh;
  padding-top: 80px;
  position: relative;
}

.Home .Aztecs {
  width: 20%;
  max-width: 150px;
  position: absolute;
  bottom: 0;
  left: 2%;
}

.Home .Container {
  position: absolute;
  top: 15%;
  left: 2%;
  color: white;
  /* color: black; */
}

.Home .Container .Hello {
  /* Keeping this font size because we want it to be bigger than the normal h1 */
  font-size: calc(1.7rem + 3vw);
}

.Home .Container h1 {
  margin: 0;
}

.Home .Right {
  color: white;
  width: 60%;
  max-width: 500px;
  position: absolute;
  right: 10%;
  top: 20%;
}
@media (max-width: 500px) {
  .Home {
    height: 72vh;
  }
}