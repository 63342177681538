.AboutMe {
  padding-bottom: 5%;
  color: white;
}

.AboutMe .Container {
  display: flex;
}

.AboutMe .Container .Text {
  width: 47%;
  margin-left: 3%;
}

.AboutMe .Container .Text h2 {
  font-weight: 900;
}

.AboutMe .Container .Photo {
  width: 50%;
  display: flex;
  justify-content: center;
  /* This align items made our photo not super stretched out because of the width: 90% below */
  align-items: center;
}

.AboutMe .Container .Photo .Me {
  width: 90%;
  max-width: 500px;
  border-radius: 10%;
}

.AboutMe a:hover {
  color: #24c6dc;
}

@media (max-width: 500px) {
  .AboutMe .Container {
    flex-direction: column;
    align-items: center;
  }
  .AboutMe .Container .Text {
    width: 80%;
  }
  .AboutMe .Container .Photo {
    margin-top: 20px;
    width: 70%;
  }
}